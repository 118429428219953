function validateBusId(busId) {
  if (busId.length > 2 && busId.length < 5) {
    return true;
  }
  return false;
}

function validateBusPass(busPass) {
  if (busPass.length > 0) {
    return true;
  }
  return false;
}



export { validateBusId, validateBusPass };