import { keys } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form, Grid, Header, Icon, Image, Message, TextArea } from 'semantic-ui-react'
import Logo from './images/logo192.png';
import { validateBusId, validateBusPass } from './validations/credentials';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ipListToArray } from './helpers/helpers';
import fetchWithTimeout from './helpers/fetch';

function getQuery() {
  const query = window.location.search[0] === '?' ? window.location.search.slice(1) : window.location.search;
  const parts = query.split('&');
  const result = {};

  for (let part of parts) {
    const chunks = part.split('=').map(decodeURIComponent);
    result[chunks[0]] = chunks[1];
  }
  return result;
}

function encodeUriObject(query) {
  if (!query) return '';
  const queryKeys = keys(query);
  return queryKeys.map(item => {
    return `${encodeURIComponent(item)}=${encodeURIComponent(query[item])}`;
  }).join('&');
}

export default function App() {
  const [user, setUser] = useState(localStorage.getItem('rsskeyuser') || '');
  const [pass, setPass] = useState(localStorage.getItem('rsskeypass') || '');
  const [token, setToken] = useState(getQuery().token || localStorage.getItem('rsskeytoken') || '');

  const [nickname, setNickname] = useState(localStorage.getItem('rsskeynickname') || '');
  const [syncResponse, setSyncResponse] = useState('');
  const [recentlyCopied, setrecentlyCopied] = useState(false);
  const [executing, setExecuting] = useState(false);
  const [valid, setValid] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [erroredInput, setErroredInput] = useState(false);
  const [advancedExpanded, setAdvancedExpanded] = useState(false);
  const [explicitIps, setExplicitIps] = useState(localStorage.getItem('explicitips') || '');
  const timer = useRef();

  const login = async () => {
    setLoggingIn(true);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/v3/getbusinesstoken?${encodeUriObject({ business_id: user, business_password: pass, no_expire: true })}`);
    if (response.status === 200) {
      const body = await response.json();
      setToken(body.token);
      setErroredInput(false);
    }
    setLoggingIn(false);
    setErroredInput(true);
  };

  const insertInputToState = (event) => {
    if (event.target.name === 'user') {
      setUser(event.target.value)
    } else {
      setPass(event.target.value)
    }
  }

  useEffect(() => {
    localStorage.setItem('rsskeyuser', user);
    localStorage.setItem('rsskeypass', pass);
    localStorage.setItem('rsskeytoken', token);
    localStorage.setItem('rsskeynickname', nickname);
    localStorage.setItem('explicitips', explicitIps);

    // validate
    if (validateBusId(user) && validateBusPass(pass)) {
      setValid(true)
    } else {
      setValid(false)
    }
  }, [user, pass, token, nickname, explicitIps]);

  const sync = useCallback(async (force = false) => {
    setExecuting(true);
    let response;
    const queryObject = {
      friendly_name: nickname || 'browser',
      force,
    };
    const manualIps = ipListToArray(explicitIps).join(',');
    if (manualIps) {
      queryObject.manual_ip_list = manualIps;
    }
    const fetchParams = {
      headers: { Authorization: `Bearer ${token}`}
    };
    const options = { timeout: 6000 };

    try {
      response = await fetchWithTimeout(`${process.env.REACT_APP_API_URL}:3389/v4/syncrsskey?${encodeUriObject(queryObject)}`, fetchParams, options);
    } catch (err) {
      console.log('failed to hit api to sync with port 3389, with error: ', err, 'trying on port 443');
      response = await fetch(`${process.env.REACT_APP_API_URL}/v4/syncrsskey?${encodeUriObject(queryObject)}`, fetchParams, options);
    }
    const raw = await response.text();
    setSyncResponse(raw);
    if (response.status > 399 && response.status < 500) {
      setToken(null);
    }
    setExecuting(false);
  }, [nickname, token, explicitIps]);

  useEffect(() => {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
    if (token) {
      timer.current = setInterval(() => {
        sync();
      }, 30000);
    }
    return () => clearInterval(timer.current);
  }, [token, sync]);

  useEffect(() => {
    sync();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      console.log('force reloading page after 24 hour countdown');
      const parts = window.location.href.split('?');
      const query = getQuery();
      query.reloads = (query.reloads || 0) + 1;
      window.location.href = parts[0] + '?' + encodeUriObject(query);
    }, 60000 * 60 * 24); // every 24 hours

    return () => clearInterval(timer);
  }, [])

  const renderListOfIps = () => {
    const explicitIpArray = ipListToArray(explicitIps);
    if (explicitIpArray) {
      return explicitIpArray.map(ip => {
        if (ip) {
          return (
            <div className="allowedIpInList">{ ip }</div>
          )
        } else {
          return '';
        }
      })
    }
  }

  if (token) {
    const url = window.location.href.split('?').shift() + '?token=' + token;
    return (
      <Grid className="mainGrid" textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as='h2' textAlign='center' title={syncResponse}>
            <Image src={ Logo } /> RSS Key
          </Header>
          <Form size='large'>
            <Message>
            <p className="remainOpenWarning">
              <Icon fitted name='exclamation triangle' />&nbsp;&nbsp;If you have a dynamic IP address (check with your Internet Service Provider), <b>this page must remain open!</b>
            </p>
              <div className="ui divider"></div>
              <Form.Input icon='desktop' iconPosition='left' placeholder='Nickname' name="user" onChange={ event => setNickname(event.target.value) } value={ nickname } />
              {executing && <Button loading icon fluid size='large' disabled={ true } onClick={() => sync(true)} >
                <Icon name="sync" />
                &nbsp;&nbsp;
                Force sync now
              </Button>}
              {!executing && <Button icon fluid size='large' disabled={ false } onClick={() => sync(true)} className="syncBtn" >
                <Icon name="sync" />
                &nbsp;&nbsp;
                Force sync now
              </Button>}
              <Button className="logoutButton" fluid size='large' onClick={() => {
                setPass('');
                setToken('');
                setErroredInput(false);
              }}>
                Log out
              </Button>
              <div className="advancedExpander" onClick={() => setAdvancedExpanded(!advancedExpanded)}>
                { advancedExpanded && <Icon fitted name='angle up' /> }
                { !advancedExpanded && <Icon fitted name='angle down' /> }
                &nbsp;
                Advanced
              </div>
              { advancedExpanded &&
                <div className="advancedSection">
                  <div className="">Add additional IP addresses to the allow-list (up to 10), separate with commas.</div>
                  <TextArea onChange={ (event) => setExplicitIps(event.target.value) } size="min" placeholder='List IPv4 addresses here' value={ explicitIps } />
                  <div className="allowedIpList">
                    { renderListOfIps() }
                  </div>
                </div>
              }
            </Message>
          </Form>

          { !recentlyCopied &&
          <CopyToClipboard text={ url } onCopy={() => {
            setrecentlyCopied(true);
            setTimeout(function(){ setrecentlyCopied(false); }, 1000);
          }} >
          <Message className="copyableToken">
            <Message.Header>Tokenized Link (click to copy)</Message.Header>
            <Message.Content>
              { url }
            </Message.Content>
          </Message>
          </CopyToClipboard>}
          { recentlyCopied &&
          <Message className="copyableToken">
            <Message.Content>
              Copied!
            </Message.Content>
          </Message>}
          <Message>
            <a href="https://resources.rossware.com/docs/rss-key" target="_blank" rel="noreferrer">Documentation</a>
          </Message>
        </Grid.Column>
      </Grid>
    )
  }

  return (
    <Grid className="mainGrid" textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h2' textAlign='center'>
          <Image src={ Logo } /> Log in to RSS Key
        </Header>
        <Form size='large'>
          <Message>
            <Form.Input icon='hashtag' iconPosition='left' placeholder='Business ID' name="user" onChange={ (event) => insertInputToState(event) } value={ user } />
            <Form.Input
              icon='lock'
              iconPosition='left'
              placeholder='Business Password'
              type='password'
              name="pass"
              onChange={ (event) => insertInputToState(event) }
            />
            { loggingIn && <Button loading fluid size='large' className={ valid ? 'loginBtnEnabled' : '' } onClick={ () => login() } disabled={ !valid }>
              Log in
            </Button> }
            { !loggingIn && <Button fluid size='large' className={ valid ? 'loginBtnEnabled' : '' } onClick={ () => login() } disabled={ !valid }>
              Log in
            </Button> }
            { erroredInput && <Message negative>
              <p>We didn't recognize those credentials. Try again or contact Rossware if you need a reset.</p>
            </Message>}
          </Message>
        </Form>
        <Message>
          Don't have business credentials? Ask your business manager or owner for a tokenized link!
        </Message>
        <Message>
          <a href="https://resources.rossware.com/docs/rss-key" target="_blank" rel="noreferrer">Documentation</a>
        </Message>
      </Grid.Column>
    </Grid>
  );
}
