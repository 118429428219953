export function ipListToArray(ipList) {
  if (!ipList) {
    return [];
  }
  const possibleIpAddresses = ipList.split(',');
  const usableIps = possibleIpAddresses.filter(possibleIp => {
    const itemToUse = possibleIp.replace(/\s+/g, '');
    const separatedByPeriods = itemToUse.split('.');
    return separatedByPeriods.length === 4 && separatedByPeriods.every(item =>  item.length > 0);
  })
  return usableIps.map(possibleIpAddress => {
    return possibleIpAddress.replace(/\s+/g, '');
  });
}
